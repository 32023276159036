import Kittymapper from '../assets/images/kittymapper.png';
import DAX from '../assets/images/dax.png';
import AND from '../assets/images/and.png';
import KittymapperAPI from '../assets/images/kittymapperAPI.png';

const projectData = [
  {
    title: 'DAX',
    description: [
      "DAX is <a href='https://global.com/dax/audio/' target='_blank' rel='noopener noreferrer' class='font-bold text-black underline'>Global Media & Entertainment's</a> Digital Advertising Exchange platform which supports targetted advertising across radio, music and podcast streaming services, reaching millions of customers a week.",
      'I was contracted to Global, via AND Digital, to work on the UI for the internal platform supporting DAX. The platform allows advertisers to manage campaigns, set target markets and ensure delivery is met as expected.',
      'In DAX I implemented features to support the roll out of the application to North America by introducing the concept of territories, limiting advertisers to viewing or editing only campaigns and ads that they were granted access to. As part of my work I also identified areas for improvement in the codebase and refactored components and tests.',
    ],
    techStack: ['TypeScript', 'React', 'Context API', 'HTML/CSS', 'GraphQL', 'Material UI', 'Formik', 'Jest', 'React Testing Library'],
    image: DAX,
  },
  {
    title: 'AND Digital',
    description: [
      'AND Digital is a consultancy focused on accelerating digital delivery for its clients, providing everything from short and focused strategic planning to end-to-end digital transformation',
      "At AND Digital I've gained a wealth of experience working on a variety of full-stack applications both internally and for clients. I helped to build an employee engagement rating tool using React as well as a client engagement rating tool in Angular.",
      "I also worked on building the UI for AND Digital's internal platform and CRM, Luna, which supports 1,000+ employees at AND. I built several features such as forms for capturing client information, and graphs and workflow charts to display prospective client information, whilst at the same time identifying and fixing bugs throughout the app.",
      "I've quickly adapted to new technologies when moving between projects and clients and I always ensure my code is written well, adheres to coding best practices and is well tested throughout.",
    ],
    techStack: [
      'JavaScript',
      'TypeScript',
      'React',
      'NextJs',
      'Angular',
      'Redux',
      'NodeJs',
      'AWS DynamoDB',
      'AWS Amplify',
      'AWS DataStore',
      'Material UI',
      'HTML/CSS',
      'Jest',
      'React Testing Library',
      'Cypress',
    ],
    image: AND,
  },
  {
    title: 'kittymapper',
    demoLink: 'https://kittymapper.netlify.app/',
    description: [
      'During lockdown, I found myself photographing neighbourhood cats on my daily mandated walk.',
      "I wanted a way to plot where and when I had seen these cats on a map, and thus <a href='https://kittymapper.netlify.app/' target='_blank' rel='noopener noreferrer' class='font-bold text-black underline'>kittymapper</a> was born.",
      'With kittymapper, you can upload your cat photos and have them automatically plotted on Google Maps!',
    ],
    techStack: ['JavaScript', 'React', 'HTML/CSS', 'Google Maps API', 'Responsive Design', 'JWT Auth', 'REST'],
    image: Kittymapper,
  },
  {
    title: 'kittymapper API',
    description: [
      "An API developed in Rails to support the kittymapper app. JWT's have been used to ensure secure authentication.",
      'Using an EXIF tool, images uploaded to the server are analysed for GPS coordinates so that the image can be automatically plotted on the map.',
      'AWS Rekognition software is used to detect the contents of the photo, ensuring only photos of cats can be uploaded.',
    ],
    techStack: ['Ruby', 'Rails', 'RSpec', 'PostgreSQL', 'JWT Auth', 'AWS S3', 'AWS Rekognition', 'ImageMagick', 'EXIF Tool', 'REST'],
    image: KittymapperAPI,
  },
];

export default projectData;
