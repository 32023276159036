import React from 'react';

const Skills = () => {
  const skillsData = [
    {
      title: 'Frontend Skills',
      skills: [
        'JavaScript',
        'TypeScript',
        'React',
        'NextJs',
        'Redux',
        'Context API',
        'HTML/CSS',
        'GraphQL',
        'Material UI',
        'Formik',
        'Jest',
        'React Testing Library',
        'Cypress',
        'Angular',
        'Tailwind CSS',
      ],
    },
    {
      title: 'Backend Skills',
      skills: ['Ruby', 'Rails', 'Sinatra', 'RSpec', 'Capybara', 'PostgreSQL', 'AWS S3', 'AWS DynamoDB', 'AWS Amplify', 'AWS DataStore', 'NodeJs'],
    },
    {
      title: 'Other Skills',
      skills: ['TDD/BDD', 'MVC', 'SOLID', 'OOP', 'REST', 'Jenkins CI', 'Travis CI', 'Agile'],
    },
  ];

  return (
    <div>
      {skillsData.map((category, index) => {
        return (
          <div key={index}>
            <h1 className='skills-header'>{category.title}</h1>
            <ul className='skills-container'>
              {category.skills.map((tech, index) => {
                return (
                  <li key={index} className='chip-list'>
                    <span className='skills-chip'>{tech}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default Skills;
