import React from 'react';

const ProjectCard = ({ project }) => {
  return (
    <div className='project'>
      <div>
        <h4 className='project-header'>{project.title}</h4>

        {project.description.map((paragraph, index) => {
          return <p className='description' key={index} dangerouslySetInnerHTML={{ __html: paragraph }}></p>;
        })}

        <ul className='list-container'>
          {project.techStack.map((tech, index) => {
            return (
              <li key={index} className='chip-list'>
                <span className='chip'>{tech}</span>
              </li>
            );
          })}
        </ul>
      </div>

      <div className='project-image'>{project.image && <img src={project.image} alt='img' />}</div>
    </div>
  );
};

export default ProjectCard;
