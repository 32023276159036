import React from 'react';

const About = () => {
  return (
    <div className='about-container'>
      <p>
        Hi there! I'm Lila, a Software Developer working at{' '}
        <a href='https://www.and.digital/' target='_blank' rel='noopener noreferrer' className='about-link'>
          AND Digital
        </a>
        , a consultancy based in London. I build frontend solutions for clients using mainly React, JavaScript and TypeScript.
      </p>
      <p>
        I'm a graduate of the renowned{' '}
        <a href='https://makers.tech/' target='_blank' rel='noopener noreferrer' className='about-link'>
          Makers Academy
        </a>
        . During bootcamp I learned to build full-stack applications with Test Driven Development whilst following coding best practices.
      </p>
      <p>
        You can find me on{' '}
        <a href='https://www.linkedin.com/in/lila-walker/' target='_blank' rel='noopener noreferrer' className='about-link'>
          LinkedIn
        </a>{' '}
        and{' '}
        <a href='https://github.com/lilawalker' target='_blank' rel='noopener noreferrer' className='about-link'>
          GitHub
        </a>
        .
      </p>
    </div>
  );
};

export default About;
