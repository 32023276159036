import React from 'react';
import ProjectCard from './ProjectCard';
import projects from './data/projectData.js';

const Work = () => {
  return (
    <div>
      {projects.map((project, index) => {
        return <ProjectCard key={index} index={index} project={project} />;
      })}
    </div>
  );
};

export default Work;
