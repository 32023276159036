import React from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import Work from './Work';
import About from './About';
import Skills from './Skills';

const App = () => {
  return (
    <Router>
      <div className='container'>
        <div className='header'>
          <h1>Lila Walker</h1>
          <h2>Software Developer in London</h2>
        </div>

        <nav>
          <ul className='navbar'>
            <li>
              <NavLink to='/'>about</NavLink>
            </li>
            <li>
              <NavLink to='/work'>work</NavLink>
            </li>
            <li>
              <NavLink to='/skills'>skills</NavLink>
            </li>
          </ul>
        </nav>

        <Switch>
          <Route path='/work'>
            <Work />
          </Route>
          <Route path='/skills'>
            <Skills />
          </Route>
          <Route path='/'>
            <About />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
